var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("data-management-header", {
        attrs: { workflowName: _vm.workflowName, tabsItems: _vm.tabsItems },
      }),
      _c("listing-component", {
        attrs: {
          type: _vm.listingType,
          "module-name": _vm.moduleName,
          headers: _vm.headers,
          "overridden-columns": _vm.overriddenColumns,
        },
        scopedSlots: _vm._u([
          {
            key: "id",
            fn: function ({ item: { id } }) {
              return [
                _c(
                  "router-link",
                  { attrs: { to: { name: _vm.routeName, params: { id } } } },
                  [
                    _c("span", { staticClass: "font-weight-medium" }, [
                      _vm._v(_vm._s(id)),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "parameters",
            fn: function ({ item: { parameters } }) {
              return [_c("p", [_vm._v(_vm._s(Object.keys(parameters).length))])]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }