
import { Component, Mixins } from 'vue-property-decorator';
import ConfigurationStatus from '@/components/data-operations/common/configuration/ConfigurationStatus.vue';

import HeaderInfosMixin from '../header-infos';

import { tailerContextConfs } from '@/store/modules/easy-firestore/tailer-context-conf';
import { CONTEXT_CONFIGURATIONS_ITEM } from '@/constants/router/routes-names';
import {
	ACTIVATED,
	CONF_CLIENT_VERSION,
	ENVIRONMENT,
	ID,
	PARAMETERS,
} from '@/constants/data-operations/listing/header-items';
import ConfigurationCollectionMixin from '@/mixins/data-operations/collection/configuration-collection-mixin';

@Component({
	components: { ConfigurationStatus },
})
export default class TailerContextConfListingView extends Mixins(HeaderInfosMixin, ConfigurationCollectionMixin) {
	moduleName: string = tailerContextConfs.moduleName;
	overriddenColumns: string[] = ['id', 'parameters'];

	get routeName() {
		return CONTEXT_CONFIGURATIONS_ITEM;
	}

	get headers() {
		return [ENVIRONMENT, CONF_CLIENT_VERSION, ID, PARAMETERS, ACTIVATED];
	}
}
